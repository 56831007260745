import styled from '@emotion/styled';
import React, { ReactElement } from 'react';
import { Row, Col } from 'react-bootstrap';

const Video = styled.video``;

const VideoSection = (): ReactElement => (
    <Row noGutters>
        <Col
            xs="12"
            style={{
                textAlign: `center`,
                paddingTop: `3rem`,
                color: `#2e598f`,
                paddingBottom: `5rem`,
            }}
        >
            <div
                style={{
                    fontSize: `2rem`,
                    paddingBottom: `2rem`,
                }}
            >
                Watch Video
            </div>
            <div>
                <Video
                    width="75%"
                    height="100%"
                    src="/videos/placeholder.mov"
                    controls
                ></Video>
            </div>
        </Col>
    </Row>
);

export default VideoSection;
