import React, { ReactElement } from 'react';
import { Container } from 'react-bootstrap';
import { Layout } from '../components/common';
import ContentImageRow from '../components/common/ContentImageRow';
import BannerSection from '../components/common/contentSections/BannerSection';
import ContentHero from '../components/common/contentSections/ContentHero';
import HeadingSection from '../components/common/contentSections/HeadingSection';
import VideoSection from '../components/common/contentSections/VideoSection';
import ImageContentRow from '../components/common/ImageContentRow';
import { ContentData } from '../utils/entities';

const BusinessServiceContentData: ContentData[] = [
    {
        id: 1,
        title: `Advanced security`,
        desc: `Cancel out repeated login in credential input at every application sign in. Once verified and authenticated, customers can log into any application with StackIAM.`,
        imageUrl: `images/business-service-advanced-security.svg`,
    },
    {
        id: 2,
        title: `Real time visibility`,
        desc: `Defend against data identity breach with SSO capabilities for custom applications`,
        imageUrl: `images/business-service-realtime-visibility.svg`,
    },
];

const BusinessService = (): ReactElement => (
    <Layout>
        <Container>
            <ContentHero
                heading="Our Business 
                Service"
                desc="We have built a trusted identity management platform for our customers. Read their case study (Insert customers story in carousel)"
                buttonText="Get Started"
                imageUrl="/images/business-service-hero.svg"
            />

            <HeadingSection
                heading="Features"
                subheading="Adaptive Multi factor Authentication"
            />
            {BusinessServiceContentData.map((item) => {
                if (item.id % 2 === 0) {
                    return <ContentImageRow key={item.id} {...item} />;
                } else {
                    return <ImageContentRow key={item.id} {...item} />;
                }
            })}
            <VideoSection />
            <BannerSection />
        </Container>
    </Layout>
);

export default BusinessService;
