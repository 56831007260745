import React, { ReactElement } from 'react';
import { Row, Col } from 'react-bootstrap';
import { BlueColoredButton } from '../Buttons';

const BannerSection = (): ReactElement => (
    <Row
        noGutters
        style={{
            background:
                `transparent url("images/banner@3x.png") no-repeat center center /cover`,
            objectFit: `contain`,
        }}
    >
        <Col
            md="12"
            lg="9"
            style={{
                paddingTop: `2rem`,
                paddingBottom: `2rem`,
                paddingLeft: `3rem`,
                paddingRight: `3rem`,
            }}
        >
            <p style={{ color: `#dbdbdb` }}>
                We Creates a potential marketplace for People where business can
                directly meet users. It also provides an interoperable access to
                user data across different industries. Users will be able to use
                the Service as a secure single-sign on provider and move their
                data across different applications.
            </p>
        </Col>
        <Col
            md="12"
            lg="3"
            style={{
                display: `flex`,
                alignItems: `center`,
                justifyContent: `center`,
                paddingBottom: `1rem`,
            }}
        >
            <div
                style={{
                    textAlign: `center`,
                    verticalAlign: `center`,
                }}
            >
                <BlueColoredButton>Get Started</BlueColoredButton>
            </div>
        </Col>
    </Row>
);

export default BannerSection;
