import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Image } from '../../utils/image';

interface ContentImageRowProps {
    title: string;
    desc: string;
    imageUrl: string;
}

const ContentImageRow = ({ title, desc, imageUrl }: ContentImageRowProps) => (
    <Row
        style={{
            paddingLeft: `6rem`,
            paddingRight: `6rem`,
            paddingTop: `1rem`,
            paddingBottom: `2rem`,
        }}
        noGutters
    >
        <Col
            md="12"
            lg="6"
            style={{
                textAlign: `center`,
                // paddingTop: '3rem',
                color: `#535b6c`,
                // paddingBottom: '5rem',
            }}
        >
            <div
                style={{
                    fontSize: `1.5rem`,
                    color: `#3c4043`,
                    fontWeight: `bold`,
                    paddingTop: `2rem`,
                    textAlign: `center`,
                }}
            >
                {title}
            </div>
            <p
                style={{
                    padding: `1rem`,
                    color: `#535b6c`,
                    textAlign: `center`,
                }}
            >
                {desc}
            </p>
        </Col>
        <Col
            md="12"
            lg="6"
            style={{
                textAlign: `center`,
                // paddingTop: '3rem',
                color: `#535b6c`,
                // paddingBottom: '5rem',
            }}
        >
            <Image src={imageUrl} />
        </Col>
    </Row>
);

export default ContentImageRow;
